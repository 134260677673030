import React from 'react';
import './CareShareRepairComponent.css';

const CareShareRepairComponent = () => {
  return (
    <div className="content-section text-cent-section ">
      {/* <h4>Designed to Last</h4> */}
      <p>Our products are designed to last, made from the best durable materials.</p>
      <p>However, if something does break, it's not the end of the line. To extend the lifecycle of our products, we offer a free repair service.</p>
      <p>Prefer something new?</p>
      <p>You can return the product to us in exchange for a 20% discount voucher for your next purchase in our webshop. We'll then repair the item and donate it to a good cause.</p>
      <a href='https://www.madeoutof.com/klantenservice#ShareRepair'>Learn more</a>       
    </div>
  );
};

export default CareShareRepairComponent;
