import React from 'react';
import './LogoSectionComponent.css';

const LogoSectionComponent = ({ data }) => {
  // console.log(data);
  return (
    <div className="content-section text-section">
      <h4>{data.title}</h4>
      <div className="logo-container">
      {data.logos.map((url, index) => (
        <img key={index} src={`/${url}`} alt={`Logo ${index + 1}`} className="logo" />
      ))}
      </div>
    </div>
  );
};

export default LogoSectionComponent;
