import React from 'react';
import './HeaderComponent.css';  // Assuming styles will be added here.
import { ReactComponent as GlobeIcon } from '../../data/globe.svg';

const HeaderComponent = ({ data }) => {
    const { title, brandLogo, thumbnail, featureComponent, benchmarkSave, compensation } = data;
    
    return (
        <div className="header-component">
            <div className="header-logo">
                <img src={brandLogo} alt={title} />
            </div>
            <div className="header-thumbnail">
                <img src={thumbnail} alt={title} />
            </div>
            <div className="header-content">
                <div className="header-title">
                    <h1>{title}</h1>
                    <span>Product Passport</span>
                    <div className="header-split-bar"></div>
                </div>
                <div className="header-features">
                    <div className="features-list">
                    {featureComponent.map((feature, index) => (
                        <div key={index} className="feature">
                            <div className='feature-title'>{feature.title}: </div>
                            <div className='feature-value'>{feature.value}</div>
                        </div>
                    ))}
                    </div>
                </div>
                <div className="header-bench">
                    { benchmarkSave &&
                    <div className="icon-block">
                        <div className="icon-container">
                            <span>{benchmarkSave}%</span>
                        </div>
                        <p>Less CO₂ output compared to production with conventional materials overseas (industry average).</p>
                    </div>
                    }
                    {compensation && 
                    <div className="icon-block">
                        <div className="icon-container">
                            <GlobeIcon />
                        </div>
                        <p>This product's CO₂ is compensated through the planting of trees in the Netherlands in collaboration with LifeTerra.</p>
                    </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default HeaderComponent;