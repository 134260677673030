import React from 'react';
import './ListSectionComponent.css';

const ListSectionComponent = ({ data }) => {
  return (
    <div className="content-section list-section">
      <h4>{data.title}</h4>
      <div className='list-container'>
        {data.data.map((item, index) => (
          <div key={index} className="list-item">
            <span style={{'textAlign':'left'}}>{item.title}</span>
            <span style={{'textAlign':'right'}}>{item.value}%</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListSectionComponent;